.nav-tabs {
    font-family: Arial;	
    font-size: 12px;	
    line-height: 14px;
    .nav-item {
        text-transform: uppercase;
        color: #939598;	
        padding: 15px;
    }
    .active {
        color: #003263;	
        font-weight: bold;
    }
}