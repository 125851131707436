.login-container {
    // background: url(../../../assets/fullscreen-bg.jpg) 50% 0px no-repeat;
    display: flex;
    justify-content: center;
    min-height: 100%;
    overflow: auto;
    align-items: center;
    padding: 20px;
    background-color: #69DDFC;
    &__content {
        box-sizing: border-box;
        width: 545px;
        border: 1px solid rgba(151, 151, 151, 0.23);
        border-radius: 6px;
        background-color: #ffffff;
        padding: 20px;
        box-shadow: 0 3px 13px 3px rgba(0, 0, 0, 0.04);
        button:first-child {
            margin-bottom: 10px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &__logo {
        height: 125px;
    }
    &-logo {
        max-width: 150px;
    }
    &__main {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &__info {
    border-radius: 50%;	
    box-sizing: border-box;
    background-color: #3389D2;
    color:#ffffff;
    height: 30px;
    border: none;
    align-items: center;
    margin-top:200px;
    margin-left: -100px;;
    width: 30px;
    font-family: "Arial";
    &:hover {
        background-color: #69DDFC
    }
    &:disabled{
        background-color: #d3d3d3;
    }
    }
    &__row{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
