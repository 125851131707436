::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

html, body, #root {
  min-height: 100% !important;
  height: 100%;
  background-color: #F7FAFF !important;
}

input[type="radio"]{
  margin: 0 10px 20px 15px;
}
.master-data__action {
  display: flex;
  background-color: #fff;
  box-shadow: 0 1px 0 0 #e8e8e8;
  align-items: center;
  border: 1px solid hsla(0,0%,59.2%,.23);
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.InputFromTo {
  display: flex;
}
.DateRage {
  padding-top: 1em;
  margin: 0.5em; // 18/12/23
}
.rdp {
  z-index: 9 !important;
  position: absolute !important;
  background: white !important;
  box-sizing: border-box !important;
  border: 1px solid #9B9B9B !important;
  border-radius: 4px !important;
  font-family: Arial !important;
  font-size: 12px !important;
  line-height: 12px !important;
    margin: 0em !important;
    text-align: left !important;
    list-style: none !important;
    background-clip: padding-box !important;
    inset: auto auto auto auto !important;
    transform: translate3d(0px, 1px, 0px) !important;
}
.dayItem.dropdown-item:hover, .dropdown-item:focus {
  color:black !important;
  background-color: #fff !important;
}
.dayMenu.dropdown-menu.show {
  inset: 25px auto auto 0px !important;
}