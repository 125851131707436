.toast-container {
    padding: 0px;

    .Toastify__toast {
        margin-bottom: 0px;
        min-height: 0px;
        padding: 0px;
    }

    .custom-toast {
        min-width: 300px;
        border-radius: 4px;	
        box-shadow: 0 0 10px 0 rgba(10,53,115,0.11);
        padding: 12px;
        color: white;
        display: flex;
        align-items: center;
        &-success {
            background-color: #29C79A;
        }
        &-error {
            background-color: #FE345E;
        }
        &-info {
            background-color: #00B1F6;
        }
        &-warning {
            background-color: darkgoldenrod; // 6/10/23
        }
        &-text {
            margin-right: 15px;
            flex: 1;
            font-size: small;
        }
        &-li-item-text{
            flex: 1;
            font-size: small;
        }
        &-close {
            margin-left: 10px;
        }
    }

    .Toastify__close-button {
        display: none;
    }
}