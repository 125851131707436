
.custom-btn {
    width: 160px;	
    border-radius: 4px;	
    background-color: #3389D2;	
    box-shadow: 0 3px 13px 3px rgba(155,155,155,0.06);
}
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }