.select {
    width: 100%;
}

.css-1wa3eu0-placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.css-1okebmr-indicatorSeparator {
    display: none !important;
}

.css-tlfecz-indicatorContainer {
    color: #4a4a4a !important;
}

.css-26l3qy-menu {
    z-index: 2 !important;
}

.css-1nmdiq5-menu {
    z-index: 9 !important; // 9/11/23
}

.css-1uccc91-singleValue {
    color: #042a6e !important;
}

.css-yk16xz-control {
    border: 1.2px solid #050b21 !important;
    border-radius: 4px !important;
}
/* 27/11/23 */
.error-select { 
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }